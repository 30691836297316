import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
// material
import { Grid } from "@mui/material";

import {
  CustomCheckBox,
  CustomTextArea,
  CustomTextBox,
} from "components/forms";
import { CustomDrawer, HoverLoader } from "components";
import { validationRule } from "utils/global";
import { useToastMessage } from "hooks";
import { services } from "redux/services";

interface Props {
  open: boolean;
  closeModal: () => void;
  formMode: "create" | "update";
  selectedData: any;
  headerText: string;
  refreshData: () => void;
}

const Form = (props: Props) => {
  const { closeModal, open, formMode, selectedData, headerText, refreshData } =
    props;
  const { toastMessage } = useToastMessage();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<any>({ mode: "all", defaultValues: { isActive: true } });
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  function onSubmit(data: any) {
    setLoading(true);
    const apiFunc =
      formMode === "create" ? "createBlogCategory" : "updateBlogCategory";
    const message =
      formMode === "create" ? "created-message" : "updated-message";
    services[apiFunc](data)
      .then((res: any) => {
        setLoading(false);
        toastMessage(
          formatMessage({ id: message }, { title: "blog-category" })
        );
        closeModal();
        refreshData();
      })
      .catch((err: any) => {
        setLoading(false);
        toastMessage(err?.data?.message, 'error');
      });
  }

  //RESET DEFAULTVALUE IN UPDATE MODE
  useEffect(() => {
    if (selectedData) {
      reset({
        uuid: selectedData.uuid,
        title: selectedData.title,
        isActive: selectedData.isActive,
      });
    }
  }, [formMode === "update"]);

  return (
    <CustomDrawer
      formMode={formMode}
      open={open}
      onCancel={closeModal}
      onSubmit={handleSubmit(onSubmit)}
      title={headerText}
    >
      <Grid container spacing={2}>
        {loading && <HoverLoader />}
        <Grid item xs={12}>
          <CustomTextBox
            label={formatMessage({ id: "title" })}
            name="title"
            control={control}
            error={errors?.title}
            rules={validationRule.textbox({ required: true, maxLength: 255 })}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <CustomCheckBox
            name="isActive"
            label={formatMessage({ id: "is-active" })}
            control={control}
          />
        </Grid>
      </Grid>
    </CustomDrawer>
  );
};

export default Form;
