import { ListAlt, Pending, Refresh, Cancel, Verified } from "@mui/icons-material";
import { Input } from "@mui/material";
import { DefaultReducerListType, DefaultReducerObjectType, TableFilterStateType, PaginationReducerType, AuthReducerObjectType, RecentRowReducerType } from "utils/types";
import { roleData } from "./role";

export const clinicalInitialRoutePath = 'art-emr';
export const genderTypeOptions = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Both', value: 3 },
];

export const constants = {
  df: "YYYY-MM-DD-MM",
  dtf: "YYYY-MM-DD hh:mm A",
  tfAmPM: "hh:mm A",
  tsf: "hh:mm:ss",
  t24hf: "HH:mm",
  t24hsf: "HH:mm:ss",
}

export const callType = [
  { label: 'Enquiry', value: "1" },
  { label: 'Appointment', value: "2" },
];

export const tableInitialState: TableFilterStateType = {
  limit: 10,
  page: 1,
  sortKey: '',
  sortType: '',
  columnFilter: [],
  filters: [],
}

export const filterTypes = {
  "text": "STRING",
  "number": "NUMBER",
  "date": "DATE",
  "select": "SELECT",
  "multiSelect": "MULTI-SELECT",
  "boolean": "BOOLEAN",
  "custom": "CUSTOM"
}

export const filterOperators = {
  "asc": 0,
  "desc": 1,
  "isLessThan": 0,
  "isLessThanOrEqualTo": 1,
  "isEqualTo": 2,
  "isNotEqualTo": 3,
  "isGreaterThanOrEqualTo": 4,
  "isGreaterThan": 5,

  "startsWith": 6,
  "endsWith": 7,
  "contains": 8,
  "isContainedIn": 9,
  "doesNotContain": 10,
}

export const numberFiltersLists = [
  { label: "Is Less Than", operator: filterOperators.isLessThan },
  { label: "Is Less Than Or Equal To", operator: filterOperators.isLessThanOrEqualTo },
  { label: "Is Equal To", operator: filterOperators.isEqualTo },
  { label: "Is Not Equal To", operator: filterOperators.isNotEqualTo },
  { label: "Is Greater Than Or Equal To", operator: filterOperators.isGreaterThanOrEqualTo },
  { label: "Is Greater Than", operator: filterOperators.isGreaterThan },
];

export const dateFiltersLists = numberFiltersLists;

export const textFiltersLists = [
  { label: "Starts With", operator: filterOperators.startsWith },
  { label: "Ends With", operator: filterOperators.endsWith },
  { label: "Contains", operator: filterOperators.contains },
  { label: "Is Contained In", operator: filterOperators.isContainedIn },
  { label: "Does Not Contain", operator: filterOperators.doesNotContain }
];

export const defaultArrayState: DefaultReducerListType = {
  loading: false,
  error: false,
  data: [],
};

export const defaultObjectState: DefaultReducerObjectType = {
  loading: false,
  error: false,
  data: {},
};

export const AuthObjectState: AuthReducerObjectType = {
  loading: false,
  error: false,
  data: {},
  status: 0
};

export const defaultPaginationState: PaginationReducerType = {
  loading: false,
  error: false,
  data: {
    items: [],
    meta: { totalItems: 0, itemCount: 0, itemsPerPage: 0, totalPages: 0, currentPage: 0 }
  },
};

export const defaultRecentRowState: RecentRowReducerType = {
  loading: false,
  error: false,
  data: {
    recentModelItems: [],
  },
};

export const genderList = [
  { label: 'Male', value:'male' },
  { label: 'Female', value: 'female' },
  { label: 'Both', value: 'both' },
];

export const statusDropDownList = [
  { label: 'Available', value:'available' ,color: "primary" },
  { label: 'Unavailable', value: 'unavailable' , color: "warning"},
  { label: 'Reserved', value: 'reserved', color: "orange" },
];

export const identityDropDownList = [
  { label: 'Citizenship', value: 'citizenship' },
  { label: 'Passport', value: 'passport' },
  { label: 'Driving Licence', value: 'licence' },
];

export const booleanTypes = {
  Yes: true,
  No: false
}

export const positiveNegativeOptions = [
  { label: "Positive", value: 1 },
  { label: "Negative", value: 2 }
]

export const yesNoOptions = [
  { label: "Yes", value: 1 },
  { label: "No", value: 2 }
]

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]

export const statusOptions = [
  { label: "In Active", value: "0", color: "error" },
  { label: "Active", value: "1", color: "secondary" },
]

// export const blogStatusList = [
//   { label: 'Pending', value:'pending', color: "warning" },
//   { label: 'Published', value: 'published', color: "secondary"},
//   { label: 'Rejected', value: 'rejected', color: "error" },
//   { label: 'Cancelled', value: 'cancelled', color: "error" },
// ];

export const enquiryStatusList = [
  { label: 'Pending', value:'pending', color: "warning" },
  { label: 'Completed', value: 'completed', color: "success"},
  { label: 'Processing', value: 'processing', color: "secondary" },
  { label: 'Cancelled', value: 'cancelled', color: "error" },
];

export const blogStatusList = [
  {
    label: "All",
    value: "all",
    color: "#616161",
    roles: [roleData.admin],
    sort: 0,
    icon: <ListAlt />,
  },
  {
    label: "Pending",
    value: "pending",
    color: "#6327e4",
    roles: [roleData.admin, roleData.writer, roleData.blog],
    sort: 2,
    icon: <Pending />,
  },
  {
    label: "Rejected",
    value: "rejected",
    color: "#ffc107",
    roles: [roleData.admin, roleData.writer, roleData.blog],
    sort: 4,
    icon: <Refresh />,
  },
  {
    label: "Cancelled",
    value: "cancelled",
    color: "#FF0000",
    roles: [roleData.admin, roleData.writer, roleData.blog],
    sort: 3,
    icon: <Cancel />,
  },
  {
    label: "Published",
    value: "published",
    color: "#008000",
    roles: [roleData.admin, roleData.writer, roleData.blog],
    sort: 1,
    icon: <Verified />,
  },
];