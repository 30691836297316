
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const View = ({ defaultData }: any) => {
    const renderValue = (name: string, value: string) => {
        return (
            <span >
                <Typography variant="subtitle2" style={{ marginRight: 10, textTransform: 'capitalize' }}>
                    {name}
                </Typography>
                <Typography variant="body2">{value}</Typography>
            </span>
        );
    };

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            {renderValue("Requested At", defaultData?.requestedAt)}
        </Grid>
        <Grid item xs={12}>
            {renderValue("Phone", defaultData?.phone)}
        </Grid>
        <Grid item xs={12}>
            {renderValue("Desired Skill", defaultData?.desiredSkill)}
        </Grid>
        <Grid item xs={12}>
            {renderValue("Project Detail", defaultData?.projectDetail)}
        </Grid>
    </Grid>
}
export default View;
