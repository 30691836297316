import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";

import CustomTable from "components/table";
import {
  DeleteButton,
  TableButtonGroup,
  TableViewButton,
} from "components/button";
import { masterPaginationServices, tableInitialState } from "utils/constants";
import { getTableParams } from "utils/global";
import { RootReducerState } from "utils/types";
import { getMasterPaginationData } from "redux/actions";
import PageLayout from "layouts/PageLayout";
import { contactUsColumns } from "utils/constants/tableColumn/column";
import { services } from "redux/services";
import { useToastMessage } from "hooks";
import { CustomModal } from "components";
import View from "./View";

const Blog: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { toastMessage } = useToastMessage();

  const [tableState, setTableState] = useState(tableInitialState);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [defaultData, setDefaultData] = useState<any>(null);

  const { tableData, loading } = useSelector(
    ({ masterPaginationReducer }: RootReducerState) => ({
      tableData:
        masterPaginationReducer[masterPaginationServices.contactUs].data,
      loading:
        masterPaginationReducer[masterPaginationServices.contactUs].loading,
    }),
    shallowEqual
  );

  const {
    items,
    meta: { totalItems },
  } = tableData;

  useEffect(() => {
    onApiCall();
  }, [tableState]);

  function onApiCall(withState: boolean = true) {
    const params = getTableParams(withState ? tableState : {});
    dispatch(
      getMasterPaginationData(masterPaginationServices.contactUs, params)
    );
  }

  let columnAction = {
    label: formatMessage({ id: "action" }),
    name: "",
    options: {
      customBodyRender: (_: any, tableMeta: any) => {
        return (
          <TableButtonGroup>
            <TableViewButton
              tooltipLabel={"View " + formatMessage({ id: "page" })}
              onClick={() => handleViewClick(items[tableMeta.rowIndex])}
            />
            <DeleteButton
              onDelete={() => onDeleteData(items[tableMeta.rowIndex])}
            />
          </TableButtonGroup>
        );
      },
    },
  };

  function handleViewClick(rowData: any) {
    setModalView(true);
    setDefaultData(rowData);
  }

  function onDeleteData(data: any) {
    setDeleteLoading(true);
    services
      .deleteContactUs(data.uuid)
      .then((res: any) => {
        onApiCall();
        toastMessage(
          formatMessage(
            { id: "deleted-message" },
            { title: formatMessage({ id: "contact-us" }) }
          )
        );
      })
      .catch((err) => {
        toastMessage(
          err?.data?.message
            ? err?.data?.message
            : formatMessage({ id: "opps-error-message" }),
          "error"
        );
      })
      .finally(() => setDeleteLoading(false));
  }

  return (
    <PageLayout title={formatMessage({ id: "contact-us" })} hideToolbar>
      <CustomTable
        columns={[...contactUsColumns(formatMessage), columnAction]}
        tableData={items}
        tableState={tableState}
        rowsCount={totalItems}
        setTableState={setTableState}
        loading={loading || deleteLoading}
      />
      {modalView && (
        <CustomModal
          open={modalView}
          onClose={() => setModalView(false)}
          size="sm"
          title={defaultData?.subject}
        >
          <View defaultData={defaultData} />
        </CustomModal>
      )}
    </PageLayout>
  );
};

export default Blog;
