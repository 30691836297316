import { shallowEqual, useSelector } from 'react-redux';
import { roleData } from 'utils/constants/role';
import { findCommonItems } from 'utils/global';
import { RootReducerState } from 'utils/types';

export function useCheckRole() {
    const { authData } = useSelector(
        ({ auth }: RootReducerState) => ({
            authData: auth.data?.user,
            loading: auth.loading,
        }),
        shallowEqual
    );

    const isSuperAdmin = findCommonItems([roleData.superadmin],authData?.roles);
    const isAdmin = findCommonItems([roleData.admin],authData?.roles);
    const isUser = findCommonItems([roleData.user],authData?.roles);
    const isBlog = findCommonItems([roleData.blog],authData?.roles);
    const isWriter = findCommonItems([roleData.writer],authData?.roles);

    return {
        isUser,
        isAdmin,
        isSuperAdmin,
        isBlog,
        isWriter
    }
}