import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useNavigate
} from "react-router-dom";

import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useIntl } from 'react-intl';

interface Props {
  children: React.ReactNode | React.ReactElement | React.ReactNode[] | React.ReactElement[];
  meta?: any;
  title?: string;
  metaTitle?: string;
  hideToolbar?: boolean
  isList?: boolean
  onCreate?: () => void;
}

const PageLayout = forwardRef(({ children, isList, title, metaTitle, hideToolbar, meta, onCreate, ...other }: Props, ref: any) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  let match = useLocation();
  return <>
    <Helmet>
      <title>{`${metaTitle} | Binary Mentor`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          {title &&
            <Typography variant="h5" color="primary" gutterBottom>
              {title} {isList && formatMessage({ id: "list" })}
            </Typography>
          }
          {!hideToolbar &&
            <Button variant="contained" onClick={() => onCreate ? onCreate() : navigate(`${match.pathname}/create`)} startIcon={<Add />}>
              Create
            </Button>
          }
        </Stack>
        {children}
      </Container>
    </Box>
  </>
});

PageLayout.defaultProps = {
  hideToolbar: false,
  isList: true
}

export default PageLayout;
