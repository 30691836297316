import { BASE_URI } from "utils/axios";

export const MASTER_API_ENDPOINTS = {
	// Master Requests
	LIST_ROLE: `${BASE_URI}/roles/list`,
	CREATE_ROLE: `${BASE_URI}/roles/save`,
	UPDATE_ROLE: `${BASE_URI}/roles/update`,
	DELETE_ROLE: `${BASE_URI}/roles/delete`,

	LIST_SKILL: `${BASE_URI}/skills/list`,
	CREATE_SKILL: `${BASE_URI}/skills/save`,
	UPDATE_SKILL: `${BASE_URI}/skills/update`,
	DELETE_SKILL: `${BASE_URI}/skills/delete`,

	LIST_SPOKEN_LANGUAGE: `${BASE_URI}/languages/list`,
	CREATE_SPOKEN_LANGUAGE: `${BASE_URI}/languages/save`,
	UPDATE_SPOKEN_LANGUAGE: `${BASE_URI}/languages/update`,
	DELETE_SPOKEN_LANGUAGE: `${BASE_URI}/languages/delete`,

	LIST_BLOG_CATEGORY: `${BASE_URI}/blog-categories/list`,
	CREATE_BLOG_CATEGORY: `${BASE_URI}/blog-categories/save`,
	UPDATE_BLOG_CATEGORY: `${BASE_URI}/blog-categories/update`,
	DELETE_BLOG_CATEGORY: `${BASE_URI}/blog-categories/delete`,

	LIST_BLOG_TAG: `${BASE_URI}/blog-tags/list`,
	CREATE_BLOG_TAG: `${BASE_URI}/blog-tags/save`,
	UPDATE_BLOG_TAG: `${BASE_URI}/blog-tags/update`,
	DELETE_BLOG_TAG: `${BASE_URI}/blog-tags/delete`,

	FILE_UPLOAD: `${BASE_URI}/files`,
};
