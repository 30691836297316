
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import {  DeleteButton, TableButtonGroup} from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { subscriberColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';

const Blog: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { tableData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            tableData: masterPaginationReducer[masterPaginationServices.subscriber].data,
            loading: masterPaginationReducer[masterPaginationServices.subscriber].loading,
        }),
        shallowEqual
    );

    const { items, meta: { totalItems } } = tableData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        dispatch(getMasterPaginationData(masterPaginationServices.subscriber, params));
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return (
                    <TableButtonGroup>
                        <DeleteButton
                            onDelete={() => onDeleteData(items[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteSubscriber(data.uuid)
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'subscriber' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout title={formatMessage({ id: "subscriber" })} metaTitle={formatMessage({ id: "subscriber" })} hideToolbar>
            <CustomTable
                columns={[...subscriberColumns(formatMessage), columnAction]}
                tableData={items}
                tableState={tableState}
                rowsCount={totalItems}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
        </PageLayout>
    )
}

export default Blog;
