import { Box, Grid, Typography } from "@mui/material";
import { useCheckRole, useToastMessage } from "hooks";
import PageLayout from "layouts/PageLayout";
import { useEffect, useState } from "react";
import { services } from "redux/services";

import { AppBlogSummary, AppCardSummary, AppWidgetSummary } from "sections/dashboard";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AppWebsiteVisits from "sections/dashboard/AppWebsiteVisits";
import { blogStatusList } from "utils/constants";
import { HoverLoader } from "components";
import { ContactMail, EmojiPeople, Looks, Message, People, Search, Work, YouTube } from "@mui/icons-material";

export default function DashboardApp() {
  const [loading, setLoading] = useState<boolean>(false);
  const { isAdmin } = useCheckRole();
  const [dashboard, setDashboard] = useState<any>({
    blogCount: 0,
    blogSummary: null,
    employeeCount: 0,
    enquiryCount: 0,
    clientCount: 0,
    newsLetterCount: 0,
    contactUsCount: 0,
    visitCount:0,
    todayVisitCount:0
  });

  const { toastMessage } = useToastMessage();

  useEffect(() => {
    services
      .dashboard()
      .then((res: any) => {
        setLoading(false);
        setDashboard(res?.data?.result);
      })
      .catch((err) => {
        setLoading(false);
        toastMessage(err.message, "error");
      });
  }, []);

  return (
    <PageLayout metaTitle="Dashboard" isList={false} hideToolbar>
      {loading && <HoverLoader />}
      <Grid container spacing={2} marginBottom={3}>
        <Grid item xs={12}>
          <Typography variant="h5" color="gray">
            Visitor Summary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <AppCardSummary
            title="Total Visitors"
            total={dashboard?.visitCount}
            icon={<People />}
            color="secondary"
            to="#"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <AppCardSummary
            title="Total Unique Visitors"
            total={dashboard?.uniqueVisitCount}
            icon={<People />}
            color="warning"
            to="#"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <AppCardSummary
            title="Today Visitors"
            total={dashboard?.todayVisitCount}
            icon={<Search />}
            color="success"
            to="#"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <AppCardSummary
            title="Today Unique Visitors"
            total={dashboard?.uniqueTodayVisitCount}
            icon={<Search />}
            color="info"
            to="#"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginBottom={3}>
        <Grid item xs={12}>
          <Typography variant="h5" color="gray">
            Blog Summary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <AppBlogSummary
            title="all"
            total={dashboard?.blogCount}
            icon={<EngineeringIcon />}
          />
        </Grid>
        {dashboard?.blogSummary &&
          blogStatusList
            ?.filter((x) => x.value != "all")
            ?.sort((a, b) => a.sort - b.sort)
            ?.map((status: any, inds: number) => (
              <Grid item lg={2} md={4} sm={6} xs={12} key={inds}>
                <AppBlogSummary
                  title={status.value}
                  total={dashboard?.blogSummary?.[status.value] || 0}
                  color={status.color}
                  icon={status.icon}
                />
              </Grid>
            ))}
      </Grid>
      {isAdmin &&
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" color="gray">
              Other Summary
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Employee"
              total={dashboard.employeeCount}
              color="primary"
              icon={<EmojiPeople />}
              to="/admin/employees"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Client"
              total={dashboard.clientCount}
              color="secondary"
              icon={<Work />}
              to="/admin/clients"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Enquiry"
              total={dashboard.enquiryCount}
              color="success"
              icon={<Message />}
              to="/admin/enquiries"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Contact Us"
              total={dashboard.contactUsCount}
              color="info"
              icon={<ContactMail />}
              to="/admin/contact-us"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Subscriber"
              total={dashboard.newsLetterCount}
              color="warning"
              icon={<YouTube />}
              to="/admin/subscriber"
            />
          </Grid>
        </Grid>
      }
      <Box sx={{ marginTop: 3 }}>
        <AppWebsiteVisits
          title="Website Visits"
          subheader="(+43%) than last year"
          chartLabels={[
            "01/01/2003",
            "02/01/2003",
            "03/01/2003",
            "04/01/2003",
            "05/01/2003",
            "06/01/2003",
            "07/01/2003",
            "08/01/2003",
            "09/01/2003",
            "10/01/2003",
            "11/01/2003",
          ]}
          chartData={[
            {
              name: "Team A",
              type: "column",
              fill: "solid",
              data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
            },
            // {
            //   name: "Team B",
            //   type: "area",
            //   fill: "gradient",
            //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
            // },
            // {
            //   name: "Team C",
            //   type: "line",
            //   fill: "solid",
            //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
            // },
          ]}
        />
      </Box>
    </PageLayout>
  );
}
