import DashboardLayout from "layouts/dashboard";
import {
  Employee,
  EmployeeForm,
  Blog,
  BlogForm,
  Role,
  SpokenLanguage,
  Dashboard,
  Setting,
  Skill,
  Banner,
  BannerForm,
  User,
  UserForm,
  BlogCategory,
  BlogTag,
  ViewBlog,
  Enquiry,
  Client,
  ClientForm,
  Page,
  PageForm,
  ContactUs,
  Subscriber,
} from "pages";
import Login from "pages/Login";
import NotFound from "pages/Page404";
import { Navigate, useRoutes } from "react-router-dom";
import { roleData } from "utils/constants/role";
import PrivateRoute from "./PrivateRoute";

export default function Router() {
  return useRoutes([
    {
      path: "/admin",
      element: (
        <PrivateRoute roles={[roleData.admin, roleData.blog, roleData.writer]}>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: (
            <PrivateRoute roles={[roleData.admin, roleData.blog, roleData.writer]}>
              <Dashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "blogs/:status",
          element: (
            <PrivateRoute roles={[roleData.admin, roleData.blog, roleData.writer]}>
              <Blog />
            </PrivateRoute>
          ),
        },
        {
          path: "blogs/create",
          element: (
            <PrivateRoute roles={[roleData.admin, roleData.blog, roleData.writer]}>
              <BlogForm />
            </PrivateRoute>
          ),
        },
        {
          path: "blogs/update/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin, roleData.blog, roleData.writer]}>
              <BlogForm />
            </PrivateRoute>
          ),
        },
        {
          path: "blogs/view/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin,roleData.blog,roleData.writer]}>
              <ViewBlog />
            </PrivateRoute>
          ),
        },
        {
          path: "banners",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Banner />
            </PrivateRoute>
          ),
        },
        {
          path: "banners/:formType",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <BannerForm />
            </PrivateRoute>
          ),
        },
        {
          path: "banners/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <BannerForm />
            </PrivateRoute>
          ),
        },
        {
          path: "clients",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Client />
            </PrivateRoute>
          ),
        },
        {
          path: "clients/:formType",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <ClientForm />
            </PrivateRoute>
          ),
        },
        {
          path: "clients/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <ClientForm />
            </PrivateRoute>
          ),
        },
        {
          path: "employees",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Employee />
            </PrivateRoute>
          ),
        },
        {
          path: "employees/:formType",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <EmployeeForm />
            </PrivateRoute>
          ),
        },
        {
          path: "employees/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <EmployeeForm />
            </PrivateRoute>
          ),
        },

        {
          path: "users",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <User />
            </PrivateRoute>
          ),
        },
        {
          path: "users/:formType",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <UserForm />
            </PrivateRoute>
          ),
        },
        {
          path: "users/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <UserForm />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/roles",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Role />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/skills",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Skill />
            </PrivateRoute>
          ),
        },
        {
          path: "profile",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Setting />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/spoken-language",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <SpokenLanguage />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/blog-categories",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <BlogCategory />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/blog-tags",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <BlogTag />
            </PrivateRoute>
          ),
        },
        {
          path: "enquiries",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Enquiry />
            </PrivateRoute>
          ),
        },
        {
          path: "pages",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Page />
            </PrivateRoute>
          ),
        },
        {
          path: "pages/:formType",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <PageForm />
            </PrivateRoute>
          ),
        },
        {
          path: "pages/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <PageForm />
            </PrivateRoute>
          ),
        },
        {
          path: "contact-us",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <ContactUs />
            </PrivateRoute>
          ),
        },
        {
          path: "subscriber",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Subscriber />
            </PrivateRoute>
          ),
        },
        { path: "404", element: <NotFound /> },
      ],
    },
    { path: "/", element: <Login /> },
    { path: "*", element: <Navigate to="/admin/404" replace /> },
  ]);
}
