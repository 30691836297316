import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";

import CustomTable from "components/table";
import {
    DeleteButton,
    TableButtonGroup,
    TableStatusButton,
    TableViewButton,
} from "components/button";
import { masterPaginationServices, tableInitialState } from "utils/constants";
import { getTableParams } from "utils/global";
import { RootReducerState } from "utils/types";
import { getMasterPaginationData } from "redux/actions";
import PageLayout from "layouts/PageLayout";
import { enquiryColumns } from "utils/constants/tableColumn/column";
import { services } from "redux/services";
import { useCheckRole, useToastMessage } from "hooks";
import UpdateStatus from "./UpdateStatus";
import { CustomModal } from "components";
import View from "./View";

const Enquiry: React.FC = () => {
    const { isAdmin } = useCheckRole();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [defaultData, setDefaultData] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { tableData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            tableData: masterPaginationReducer[masterPaginationServices.enquiry].data,
            loading:
                masterPaginationReducer[masterPaginationServices.enquiry].loading,
        }),
        shallowEqual
    );

    const {
        items,
        meta: { totalItems },
    } = tableData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        dispatch(getMasterPaginationData(masterPaginationServices.enquiry, params));
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return (
                    <TableButtonGroup>
                         <TableViewButton
                            tooltipLabel={"View " + formatMessage({ id: "enquiry" })}
                            onClick={() => {
                                setModalView(true);
                                setDefaultData(items[tableMeta.rowIndex]);
                            }}
                        />
                        <TableStatusButton
                            tooltipLabel={
                                "Update " + formatMessage({ id: "enquiry" }) + " Status"
                            }
                            onClick={() => {
                                handleClickOpen();
                                setDefaultData(items[tableMeta.rowIndex]);
                            }}
                        />
                        <DeleteButton
                            onDelete={() => onDeleteData(items[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                );
            },
        },
    };

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services
            .deleteEnquiry(data.uuid)
            .then((res: any) => {
                onApiCall();
                toastMessage(
                    formatMessage(
                        { id: "deleted-message" },
                        { title: formatMessage({ id: "blog" }) }
                    )
                );
            })
            .catch((err) => {
                toastMessage(
                    err?.data?.message
                        ? err?.data?.message
                        : formatMessage({ id: "opps-error-message" }),
                    "error"
                );
            })
            .finally(() => setDeleteLoading(false));
    }

    return (
        <PageLayout title={formatMessage({ id: "Enquiry" })}>
            <CustomTable
                columns={[...enquiryColumns(formatMessage), columnAction]}
                tableData={items}
                tableState={tableState}
                rowsCount={totalItems}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
            <UpdateStatus
                open={open}
                handleClose={handleClose}
                defaultData={defaultData}
                refreshData={onApiCall}
            />
            {modalView && (
                <CustomModal
                    open={modalView}
                    onClose={() => setModalView(false)}
                    size="sm"
                >
                    <View defaultData={defaultData} />
                </CustomModal>
            )}
        </PageLayout>
    );
};

export default Enquiry;
