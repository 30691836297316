import { Grid, Typography } from "@mui/material";
import { CustomModal } from "components";
import SectionLayout from "layouts/SectionLayout";
import useResponsive from "hooks/useResponsive";
import parse from 'html-react-parser';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedData: any;
  headerText: string;
}

const View = (props: Props) => {
  const { onClose, open, selectedData, headerText } = props;
  const renderValue = (name: string, value: string) => {
    return (
      <span >
        <Typography variant="subtitle2" style={{ marginRight: 10,textTransform:'capitalize' }}>
          {name}
        </Typography>
        <Typography variant="body2">{value ? parse(value) : value}</Typography>
      </span>
    );
  };
  const isDesktop = useResponsive("up", "lg");
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={headerText}
      size={isDesktop ? "lg" : "xs"}
    >
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <img src={selectedData?.photo} height="150px" />
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue("Full Name", selectedData?.fullName)}
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue("Email", selectedData?.email)}
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue("Designation", selectedData?.designation)}
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue("Phone", selectedData?.phone)}
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue("DOB", selectedData?.dob)}
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue("Gender", selectedData?.gender)}
            </Grid>
            {/* <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue("Language", selectedData?.language)}
            </Grid> */}
            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue(
                "Identity Type",
                selectedData?.identityType
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue(`${selectedData?.identityType} No.`, selectedData?.identityNumber)}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              {renderValue("Total year of experience", selectedData?.totalExperience)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginY: 2 }}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          {renderValue("Address", selectedData?.address)}
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          {renderValue(
            "City",
            selectedData?.city
          )}
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          {renderValue(
            "Country",
            selectedData?.country
          )}
        </Grid>
        <Grid item xs={12}>
          {renderValue("Profile", selectedData?.profile)}
        </Grid>
        <Grid item xs={12}>
          {renderValue("Certificate", selectedData?.certificateDetail)}
        </Grid>
        <Grid item xs={12}>
          {renderValue("Academic", selectedData?.academicDetail)}
        </Grid>
        <Grid item xs={12}>
          {renderValue("Experience", selectedData?.experienceDetail)}
        </Grid>

      </Grid>
    </CustomModal>
  );
};

export default View;
