
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, TableButtonGroup, TableStatusButton, TableViewButton } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { pageColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import UpdateStatus from './UpdateStatus';
import { CustomModal } from 'components';
import View from './View';

const Blog: React.FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { toastMessage } = useToastMessage();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [defaultData, setDefaultData] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const { tableData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            tableData: masterPaginationReducer[masterPaginationServices.page].data,
            loading: masterPaginationReducer[masterPaginationServices.page].loading,
        }),
        shallowEqual
    );

    const { items, meta: { totalItems } } = tableData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        dispatch(getMasterPaginationData(masterPaginationServices.page, params));
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return (
                    <TableButtonGroup>
                        <TableViewButton
                            tooltipLabel={"View " + formatMessage({ id: "page" })}
                            onClick={() => handleViewClick(items[tableMeta.rowIndex])}
                        />
                        <TableEditButton
                            tooltipLabel={"Edit " + formatMessage({ id: "page" })}
                            onClick={() => handleRowClick(items[tableMeta.rowIndex])}
                        />
                        <DeleteButton
                            onDelete={() => onDeleteData(items[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    function handleRowClick(rowData: any) {
        navigate(`${pathname}/update/${rowData?.uuid}`, { ...rowData });
    }

    function handleViewClick(rowData: any) {
        setModalView(true)
        setDefaultData(rowData)
        // navigate(`${pathname}/view/${rowData?.uuid}`, { ...rowData });
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deletePage(data.uuid)
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'page' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout title={formatMessage({ id: "page" })} metaTitle={formatMessage({ id: "page" })}>
            <CustomTable
                columns={[...pageColumns(formatMessage), columnAction]}
                tableData={items}
                tableState={tableState}
                rowsCount={totalItems}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
            <UpdateStatus
                open={open}
                handleClose={handleClose}
                defaultData={defaultData}
                refreshData={onApiCall}
            />
            {modalView && <CustomModal open={modalView} onClose={() => setModalView(false)} size='xl'>
                <View
                    defaultData={defaultData}
                />
            </CustomModal>}
        </PageLayout>
    )
}

export default Blog;
