import { Close } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Container,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const View = ({ defaultData, onClose }: any) => {
    const [description, setDescription] = useState("");
    const [toc, setToc] = useState([]);

    useEffect(() => {
        if (defaultData?.longDescription) {
            const generateToc = () => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(
                    defaultData?.longDescription,
                    "text/html"
                );

                const headings: any = Array.from(
                    doc.querySelectorAll("h1, h2, h3, h4, h5, h6")
                );

                // Add 'id' attributes to the headings if they don't exist
                headings.forEach(
                    (heading: {
                        textContent: string;
                        setAttribute: (arg0: string, arg1: string) => void;
                    }) => {
                        const text = heading.textContent.trim();
                        const id = encodeURIComponent(
                            text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")
                        );
                        heading.setAttribute("id", id);
                    }
                );

                // Serialize the modified HTML content back to raw HTML
                const bodyContent = doc.querySelector("body")?.innerHTML as any;

                setDescription(bodyContent);

                // Generate the TOC based on the modified HTML content
                const tocData: any = headings?.map(
                    (heading: { textContent: string }) => {
                        const text = heading.textContent.trim();
                        const link = `#${encodeURIComponent(
                            text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")
                        )}`;
                        if (text) return { text, link };
                    }
                );

                setToc(tocData);
            };

            generateToc();
        }
    }, [defaultData?.longDescription]);

    const scrollToHeading = (link: any) => {
        const targetElement = document?.querySelector(link);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <Container maxWidth="xl">
            <Box sx={{ paddingX: 4, paddingBottom: 5 }}>
                <AppBar
                    position="sticky"
                    color="default"
                    elevation={1}
                    sx={{
                        marginBottom: 3,
                        padding:2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <Box display="flex" justifyContent="end" marginTop={1}>
                        <IconButton sx={{ border: 1 }} onClick={onClose} color="error">
                            <Close />
                        </IconButton>
                    </Box>
                    <Typography variant="h3">{defaultData?.title}</Typography>
                    <Typography variant="body1">
                        {defaultData?.shortDescription}
                    </Typography>

                </AppBar>

                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} xs={12}>
                        <List
                            sx={{ background: "#f5f5f5", position: "fixed", width: "22%" }}
                        >
                            {toc?.map((item: any, index) => (
                                <ListItem key={index}>
                                    <a
                                        href={item.link}
                                        onClick={() => scrollToHeading(item.link)}
                                    >
                                        <ListItemText primary={`${index + 1}. ${item.text}`} />
                                    </a>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item lg={9} md={9} xs={12}>
                        <img
                            src={defaultData?.featureImage}
                            alt={defaultData?.featureImageAlt}
                            height={350}
                            width={'100%'}
                            style={{ objectFit: 'cover', objectPosition: 'center' }}
                        />
                        <div className="ql-snow">
                            <div
                                className="ql-editor"
                                dangerouslySetInnerHTML={{ __html: description }}
                            ></div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
export default View;
