import { BASE_URI } from "utils/axios";

export const API_ENDPOINTS = {
	LOGIN: `${BASE_URI}/auth/admin/login`,
	GET_ACCOUNT: `${BASE_URI}/get-account`,
	SELECT_ACCOUNT: `${BASE_URI}/select-account`,
	LOGOUT: `${BASE_URI}/logout`,
	DASHBOARD: `${BASE_URI}/dashboard`,

	LIST_EMPLOYEE: `${BASE_URI}/employees/list`,
	GET_EMPLOYEE_BY_UUID: `${BASE_URI}/employees/getByUuid`,
	CREATE_EMPLOYEE: `${BASE_URI}/employees/save`,
	UPDATE_EMPLOYEE: `${BASE_URI}/employees/update`,
	UPDATE_EMPLOYEE_FLAG: `${BASE_URI}/employees/update-flag`,
	DELETE_EMPLOYEE: `${BASE_URI}/employees/delete`,
	EMPLOYEE_LOOKUP: `${BASE_URI}/employees/lookup`,

	LIST_BANNER: `${BASE_URI}/banners/list`,
	GET_BANNER_BY_UUID: `${BASE_URI}/banners/getByUuid`,
	CREATE_BANNER: `${BASE_URI}/banners/save`,
	UPDATE_BANNER: `${BASE_URI}/banners/update`,
	DELETE_BANNER: `${BASE_URI}/banners/delete`,

	LIST_BLOG: `${BASE_URI}/blogs/list`,
	GET_BLOG_BY_UUID: `${BASE_URI}/blogs/getByUuid`,
	CREATE_BLOG: `${BASE_URI}/blogs/save`,
	UPDATE_BLOG: `${BASE_URI}/blogs/update`,
	DELETE_BLOG: `${BASE_URI}/blogs/delete`,
	UPDATE_BLOG_STATUS: `${BASE_URI}/blogs/update-status`,
	UPDATE_BLOG_FEATURED: `${BASE_URI}/blogs/update-featured`,
	BLOG_LOOKUP: `${BASE_URI}/blogs/lookup`,
	
	LIST_USER: `${BASE_URI}/users/list`,
	GET_USER_BY_UUID: `${BASE_URI}/users/getByUuid`,
	CREATE_USER: `${BASE_URI}/users/save`,
	UPDATE_USER: `${BASE_URI}/users/update`,
	UPDATE_USER_FLAG: `${BASE_URI}/users/update-flag`,
	DELETE_USER: `${BASE_URI}/users/delete`,
	USER_LOOKUP: `${BASE_URI}/users/lookup`,
	USER_UPDATE_PASSWORD: `${BASE_URI}/users/updatePassword`,

	LIST_ENQUIRY: `${BASE_URI}/enquiry/list`,
	GET_ENQUIRY_BY_UUID: `${BASE_URI}/enquiry/getByUuid`,
	DELETE_ENQUIRY: `${BASE_URI}/enquiry/delete`,
	UPDATE_ENQUIRY_STATUS: `${BASE_URI}/enquiry/update-status`,

	LIST_CLIENT: `${BASE_URI}/clients/list`,
	GET_CLIENT_BY_UUID: `${BASE_URI}/clients/getByUuid`,
	CREATE_CLIENT: `${BASE_URI}/clients/save`,
	UPDATE_CLIENT: `${BASE_URI}/clients/update`,
	DELETE_CLIENT: `${BASE_URI}/clients/delete`,

	LIST_PAGE: `${BASE_URI}/pages/list`,
	GET_PAGE_BY_UUID: `${BASE_URI}/pages/getByUuid`,
	CREATE_PAGE: `${BASE_URI}/pages/save`,
	UPDATE_PAGE: `${BASE_URI}/pages/update`,
	DELETE_PAGE: `${BASE_URI}/pages/delete`,

	LIST_SUBSCRIBER: `${BASE_URI}/newsletter/list`,
	DELETE_SUBSCRIBER: `${BASE_URI}/newsletter/delete`,

	LIST_CONTACT_US: `${BASE_URI}/contact-us/list`,
	DELETE_CONTACT_US: `${BASE_URI}/contact-us/delete`,
};
