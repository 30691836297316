import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SlideTransition } from './SlideTransition';

interface CommonProps {
  onAgree?: () => void;
  onDisagree: () => void;
  open: boolean;
}
interface Props extends CommonProps {
  title?: string;
  subTitle?: string;
  agreeLabel?: string;
  disagreeLabel?: string;
  dialogContent?: any;
}

const CustomDialog = (props: Props) => {
  const { title, subTitle, onAgree, onDisagree, agreeLabel, disagreeLabel, open, dialogContent } = props;
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // disableBackdropClick
    >
      <DialogTitle sx={{ background: theme.palette.primary.main, color:theme.palette.primary.contrastText }} id="alert-dialog-title">
        <Typography variant="body1" sx={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>{title ?? formatMessage({ id: "title" })}</Typography>
      </DialogTitle>

      <DialogContent>
        {dialogContent ?? (
          <>
            <DialogContentText id="alert-dialog-description" sx={{ fontSize: "15px", textAlign: "center", color: theme.palette.text.primary, marginTop: "10px" }}>
              {subTitle ?? formatMessage({ id: "sub-title" })}
            </DialogContentText>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onDisagree} color="error" variant="outlined">
          {disagreeLabel ?? formatMessage({ id: "disagree" })}
        </Button>
        {onAgree &&
          <Button onClick={onAgree} color="secondary" variant="contained">
            {agreeLabel ?? formatMessage({ id: "agree" })}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export { CustomDialog }

const AuthActiveSessionDialog = (props: CommonProps) => {
  const { onAgree, onDisagree, open } = props;
  const { formatMessage } = useIntl();

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ background: "#F4E165" }} id="alert-dialog-title">
        <Typography variant="body1" sx={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>{formatMessage({ id: "close-active-session" })}</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: "15px", textAlign: "center", color: "#000000", marginTop: "10px" }}>
          You already have another active session. Would you like it to be closed automatically?
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: "15px", textAlign: "center", color: "red", fontWeight: "bold" }}>
          Remainder: If you click 'Yes', you will loose your unsaved data!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onDisagree} color='error' variant="outlined" >
          {formatMessage({ id: "no" })}
        </Button>
        <Button onClick={onAgree} color="secondary" variant="contained">
          {formatMessage({ id: "yes" })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { AuthActiveSessionDialog };