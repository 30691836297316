import { MASTER_API_ENDPOINTS } from "redux/masterApiEndpoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any;
}

export const masterServices = {

  createRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_ROLE, body),
  updateRole: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_ROLE, body),
  getAllRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_ROLE, body),
  deleteRole: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_ROLE, body),

  createSkill: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SKILL, body),
  updateSkill: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_SKILL, body),
  getAllSkill: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SKILL, body),
  deleteSkill: (uuid:string) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SKILL + "/" + uuid),

  createSpokenLanguage: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SPOKEN_LANGUAGE, body),
  updateSpokenLanguage: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_SPOKEN_LANGUAGE, body),
  getAllSpokenLanguage: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SPOKEN_LANGUAGE, body),
  deleteSpokenLanguage: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SPOKEN_LANGUAGE, body),

  createBlogCategory: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_BLOG_CATEGORY, body),
  updateBlogCategory: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_BLOG_CATEGORY, body),
  getAllBlogCategory: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_BLOG_CATEGORY, body),
  deleteBlogCategory: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_BLOG_CATEGORY, body),

  createBlogTag: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_BLOG_TAG, body),
  updateBlogTag: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_BLOG_TAG, body),
  getAllBlogTag: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_BLOG_TAG, body),
  deleteBlogTag: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_BLOG_TAG, body),

  filesUpload: (body:ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.FILE_UPLOAD,body),

};
