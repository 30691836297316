import { services } from "redux/services";

const check = <T extends string>(
  collection: Record<T, keyof typeof services>
) => collection;

export const masterPaginationServices = check({
  employee: "getAllEmployee",
  blog: "getAllBlog",
  banner: "getAllBanner",
  role: "getAllRole",
  skill: "getAllSkill",
  spokenLanguage: "getAllSpokenLanguage",
  blogCategory: "getAllBlogCategory",
  blogTag: "getAllBlogTag",
  user: "getAllUser",
  enquiry:"getAllEnquiry",
  client : 'getAllClient',
  page : 'getAllPage',
  subscriber : 'getAllSubscriber',
  contactUs : 'getAllContactUs',
});
