import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosPromise,
	AxiosResponse,
	AxiosError,
} from "axios";
import { merge } from "lodash";
import { jsonToFormData } from "utils/global";
import { store } from 'redux/store';

// utils
export interface AjaxOptions {
	// headerContentLocale?: string;
	baseURL: string;
	headers?: any;
	headerAuthorization?: any;
	onRequest?: (config: AxiosRequestConfig) => AxiosRequestConfig;
	onRequestError?: (error: any) => void;
	onResponse?: (response: any) => any;
	onResponseError?: (error: any) => any;
}

export type AjaxError = AxiosResponse;

export class Ajax {
	static options: any;
	static props: any;
	public static setGlobalOptions(options: AjaxOptions) {
		axios.defaults = merge({}, axios.defaults, Ajax.buildOptions(options));
	}

	private static async buildOptions(options: AjaxOptions & any): Promise<AxiosRequestConfig> {
		if (!options) {
			return {};
		}

		const config: AxiosRequestConfig & any = {};
		if (options.baseURL) {
			config.baseURL = options.baseURL;
		}
		if (!config.headers) {
			config.headers = {};
		}
		if (!config.headers.common) {
			config.headers.common = {};
		}
		const { auth }:any = store.getState();

		// const authorization =
		// 	typeof options.headerAuthorization === "string"
		// 		? options.headerAuthorization
		// 		: await options.headerAuthorization();

		if(auth?.data?.accessToken){
			const keyAuthorization = "Authorization";
			config.headers.common[keyAuthorization] = "Bearer " + auth?.data?.accessToken;
		}

		if (options.headers) {
			if (!config.headers) {
				config.headers = {};
			}
			if (!config.headers.common) {
				config.headers.common = {};
			}
			for (const key of Object.keys(options.headers)) {
				config.headers.common[key] = options.headers[key];
			}
		}
		return config;
	}

	private static async instance(options?: AjaxOptions): Promise<AxiosInstance> {
		const result: AxiosInstance = options
			? axios.create(await Ajax.buildOptions(options))
			: axios.create();

		if (options) {
			if (options.onRequest || options.onRequestError) {
				result.interceptors.request.use(
					options.onRequest || ((config: AxiosRequestConfig) => config),
					options.onRequestError || ((error: any) => Promise.reject(error))
				);
			}
			if (options.onResponse || options.onResponseError) {
				result.interceptors.response.use(
					options.onResponse || ((response: any) => response),
					options.onResponseError || ((error: any) => Promise.reject(error))
				);
			}
		}

		result.interceptors.response.use(
			(response: AxiosResponse) => response.data,
			(error: AxiosError & any) => {
				if (error?.response?.status === 401 || error?.response?.statusCode === 401) {
					localStorage.clear();
					// this.props.history.push("/");
				}
				return Promise.reject(error?.response);
			}
		);

		return result;
	}

	private options: AjaxOptions;

	public constructor(options?: any) {
		this.options = options;
	}

	public instance = async (): Promise<AxiosInstance> =>
		await Ajax.instance(this.options);

	public get = async (url: string, data: any = {}): Promise<AxiosPromise> => {
		return (await this.instance()).get(url, { params: data }) as AxiosPromise;
	};
	public post = async (url: string, data: any): Promise<AxiosPromise> => {
		return (await this.instance()).post(url, data);
	};
	public postForm = async (url: string, data: any): Promise<AxiosPromise> => {
		const formData = jsonToFormData(data);
		return (await this.instance()).post(url, formData);
	};
	public putForm = async (url: string, data: any): Promise<AxiosPromise> => {
		const formData = jsonToFormData(data);
		return (await this.instance()).put(url, formData);
	};
	public remove = async (url: string): Promise<AxiosPromise> => {
		return (await this.instance()).delete(url);
	};
	public delete = async (
		url: string,
		data: any = null
	): Promise<AxiosPromise> => {
		return (await this.instance()).delete(url, { params: data });
	};
	
	public put = async (url: string, data: any): Promise<AxiosPromise> => {
		return (await this.instance()).put(url, data);
	};
	public patch = async (url: string, data: any): Promise<AxiosPromise> => {
		return (await this.instance()).patch(url, data);
	};

	public patchForm = async (url: string, data: any): Promise<AxiosPromise> => {
		const formData = jsonToFormData(data);
		return (await this.instance()).patch(url, formData);
	};
}

const ajax = new Ajax();

export default ajax;
