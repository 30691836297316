import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomTextArea, CustomTextBox, CustomTextEditor } from 'components/forms';
import { HoverLoader } from 'components';
import { slugify, validationRule } from 'utils/global';
import {  useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [updateId, setUpdateId] = useState<number>(0);
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState<any>(null);

    const pageUuid = localStorage.getItem('page-uuid');

    function onSubmit(data: any) {
        setLoading(true);
        if (formType === 'update') {
            data.id = updateId;
            data.uuid = uuid;
        } else {
            data.imageUuid = pageUuid;
        }
        const apiFunc = formType === 'create' ? 'createPage' : 'updatePage';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'page' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getPageByUuid(uuid)
                .then((res: any) => {
                    const response = res.result;
                    setUpdateId(+response.id);
                    setDefaultData(response);
                })
                .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
                }).finally(() => setLoading(false))
        }

    }, [formType === 'update', uuid]);

    useEffect(() => {
        if (defaultData) {
            delete defaultData.photo;
            reset({
                imageUuid: defaultData.imageUuid,
                slug: defaultData.slug,
                title: defaultData.title,
                subTitle: defaultData.subTitle,
                shortDescription: defaultData.shortDescription,
                longDescription: defaultData.longDescription,
                isActive: defaultData.isActive,
                metaTitle: defaultData.metaTitle,
                metaDescription: defaultData.metaDescription,
                metaKeyword: defaultData.metaKeyword,
            });
        }
    }, [defaultData]);

    useEffect(() => {
        if (watch('title')) {
            setValue('slug', slugify(watch('title')))
        }

    }, [watch('title')])

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "page" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionLayout title={formatMessage({ id: "information" })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "slug" })}
                                    name="slug"
                                    control={control}
                                    error={errors?.slug}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "title" })}
                                    name="title"
                                    control={control}
                                    error={errors?.title}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "sub-title" })}
                                    name="subTitle"
                                    control={control}
                                    error={errors?.subTitle}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextArea
                                    formLabel={formatMessage({ id: "short-description" })}
                                    name="shortDescription"
                                    control={control}
                                    minRows={5}
                                    error={errors?.shortDescription}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextEditor
                                    name="longDescription"
                                    defaultValue={watch('longDescription')}
                                    error={errors?.longDescription}
                                    onChange={(editor: any) => setValue('longDescription', editor)}
                                    uuid={pageUuid as string ?? defaultData?.imageUuid}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "meta-title" })}
                                    name="metaTitle"
                                    control={control}
                                    error={errors?.metaTitle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextArea
                                    formLabel={formatMessage({ id: "meta-description" })}
                                    name="metaDescription"
                                    control={control}
                                    minRows={7}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomCheckBox
                                    name="isActive"
                                    label={formatMessage({ id: "is-active" })}
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    </SectionLayout>
                </Grid>
            </Grid>
        </FormLayout>
    )
}

export default Form;