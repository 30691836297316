import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Alert, Box, Grid } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox, CustomTextEditor } from 'components/forms';
import { HoverLoader } from 'components';
import { slugify, validationRule } from 'utils/global';
import { useCreateUuidLookupOptions, useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'utils/types';
import { FileAcceptData, fileValidate, masterLookupServices } from 'utils/constants';
import { getMasterLookupData } from 'redux/actions';
import { CustomButton } from 'components/button';
import BlogCategory from 'pages/master/blogCategory/Form';
import BlogTag from 'pages/master/blogTag/Form';
import { Add } from '@mui/icons-material';

const Form: React.FC = () => {
    const { uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updateId, setUpdateId] = useState<number>(0);
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState<any>(null);
    const [fileImage, setFileImage] = useState('');
    const [blogCategory, setBlogCategoryModal] = useState(false);
    const [blogTag, setBlogTagModal] = useState(false);
    const [validationError, setValidationError] = useState([]);

    const blogUuid = localStorage.getItem('blog-uuid');

    const { blogLookUp } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                blogLookUp: masterLookupReducer[masterLookupServices.getBlogLookup].data
            })
        },
        shallowEqual
    );

    const selectOptions = useCreateUuidLookupOptions(blogLookUp);

    function lookUpCall() {
        dispatch(getMasterLookupData(masterLookupServices.getBlogLookup));
    }

    useEffect(() => {
        lookUpCall()
    }, [])

    function onSubmit(data: any) {
        if (!data.longDescription) {
            toastMessage('Blog description required', 'error');
            return;
        }
        setValidationError([]);
        setLoading(true);
        data.featureImage = data?.featureImage?.[0];
        data.tags = data?.tags?.map((item: any) => item.value);
        data.categories = data.categories.map((item: any) => item.value);
        data.featureImageAlt = data.featureImageAlt ?? data.slug;
        if (uuid) {
            data.id = updateId;
            data.uuid = uuid;
        } else {
            if (!blogUuid) {
                toastMessage('Blog uuid is not found. Please refresh this page and try again.', 'error');
                setLoading(false)
                return;
            }
            data.imageUuid = blogUuid;
        }
        const apiFunc = !uuid ? 'createBlog' : 'updateBlog';
        const message = !uuid ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'blog' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                if (err.status === 422) {
                    setValidationError(err?.data?.error);
                    toastMessage("Mandatory field is missing", 'error');
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                } else {
                    toastMessage(JSON.stringify(err?.data?.message), 'error');
                }
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getBlogByUuid(uuid)
                .then((res: any) => {
                    const response = res.result;
                    setUpdateId(+response.id);
                    setDefaultData(response);
                    setFileImage(response.featureImage)
                })
                .catch((err: any) => {
                    toastMessage(err?.data?.message, 'error');
                }).finally(() =>
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                )
        }

    }, [uuid]);

    useEffect(() => {
        function updateData() {
            delete defaultData.photo;
            reset({
                imageUuid: defaultData.imageUuid,
                slug: defaultData.slug,
                title: defaultData.title,
                subTitle: defaultData.subTitle,
                shortDescription: defaultData.shortDescription,
                longDescription: defaultData.longDescription,
                // isActive: defaultData.isActive,
                // isFeatured: defaultData.isFeatured,
                metaTitle: defaultData.metaTitle,
                metaDescription: defaultData.metaDescription,
                metaKeyword: defaultData.metaKeyword,
                featureImageAlt: defaultData.featureImageAlt,
                featureImage: null,
                categories: selectOptions?.blogCategory?.filter((item: any) => defaultData?.categories?.map((l: any) => l.uuid)?.includes(item.value)),
                tags: selectOptions?.blogTag?.filter((item: any) => defaultData?.tags?.map((s: any) => s.uuid)?.includes(item.value)),
            });
        }
        if (defaultData) {
            setTimeout(() => {
                updateData();
            }, 1000);
        }
    }, [defaultData, selectOptions]);

    useEffect(() => {
        if (watch('title')) {
            setValue('slug', slugify(watch('title')))
        }

    }, [watch('title')])

    return (
        <FormLayout formType={uuid ? 'update' : 'create'} title={formatMessage({ id: "blog" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <Grid container spacing={2}>
                {validationError?.length > 0 &&
                    <Grid item xs={12}>
                        {validationError?.map((e, i) => <Alert severity="error" key={i}>{e}</Alert>)}
                    </Grid>
                }
                <Grid item xs={8}>
                    <SectionLayout title={formatMessage({ id: "blog-information" })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "slug" })}
                                    name="slug"
                                    control={control}
                                    error={errors?.slug}
                                    rules={validationRule.textbox({ required: true, maxLength: 255 })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "title" })}
                                    name="title"
                                    control={control}
                                    error={errors?.title}
                                    rules={validationRule.textbox({ required: true, maxLength: 255 })}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "sub-title" })}
                                    name="subTitle"
                                    control={control}
                                    error={errors?.subTitle}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <CustomTextArea
                                    formLabel={formatMessage({ id: "short-description" })}
                                    name="shortDescription"
                                    control={control}
                                    minRows={8}
                                    error={errors?.shortDescription}
                                    rules={validationRule.textbox({ required: true, maxLength: 2048 })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <CustomSelect
                                        options={selectOptions?.blogCategory ?? []}
                                        label={formatMessage({ id: "category" })}
                                        name="categories"
                                        control={control}
                                        error={errors.categories}
                                        rules={{ required: true }}
                                        defaultValue={watch('categories') || []}
                                        multiple
                                    />
                                    <CustomButton
                                        startIcon={<Add />}
                                        label="Add"
                                        size="large"
                                        color="primary"
                                        onClick={() => setBlogCategoryModal(true)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <CustomSelect
                                        options={selectOptions?.blogTag ?? []}
                                        label={formatMessage({ id: "tag" })}
                                        name="tags"
                                        control={control}
                                        error={errors.tags}
                                        rules={{ required: true }}
                                        defaultValue={watch('tags') || []}
                                        multiple
                                    />
                                    <CustomButton
                                        startIcon={<Add />}
                                        label="Add"
                                        size="large"
                                        color="primary"
                                        onClick={() => setBlogTagModal(true)}
                                    />
                                </Box>
                            </Grid>


                        </Grid>
                    </SectionLayout>
                </Grid>
                <Grid item xs={4}>
                    {/* <SectionLayout title={formatMessage({ id: "Status" })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomCheckBox
                                    name="isActive"
                                    label={formatMessage({ id: "is-active" })}
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomCheckBox
                                    name="isFeatured"
                                    label={formatMessage({ id: "is-featured" })}
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    </SectionLayout> */}
                    <SectionLayout title={formatMessage({ id: "featured-image" })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "image-alt" })}
                                    name="featureImageAlt"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <CustomFileUpload
                                    accept={FileAcceptData.IMAGES}
                                    label={formatMessage({ id: "image" })}
                                    error={errors?.logo}
                                    {...register('featureImage', {
                                        validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                                    })}
                                    defaultFileUrl={fileImage}
                                    defaultFileName={''}
                                    onClear={() => {
                                        setValue(`featureImage`, null);
                                        clearErrors(`featureImage`);
                                    }}
                                    onChange={(file: any) => setValue('featureImage', file)}
                                />
                            </Grid>
                        </Grid>
                    </SectionLayout>
                </Grid>
            </Grid>
            <Box marginBottom={2}>
                <CustomTextEditor
                    name="longDescription"
                    defaultValue={watch('longDescription')}
                    error={errors?.longDescription}
                    onChange={(editor: any) => setValue('longDescription', editor)}
                    uuid={blogUuid as string ?? defaultData?.imageUuid}
                />
            </Box>
            <SectionLayout title={formatMessage({ id: "seo-information" })}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "meta-title" })}
                            name="metaTitle"
                            control={control}
                            error={errors?.metaTitle}
                            rules={validationRule.textbox({ required: true, maxLength: 255 })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "meta-description" })}
                            name="metaDescription"
                            control={control}
                            minRows={7}
                            error={errors?.metaDescription}
                            rules={validationRule.textbox({ required: true, maxLength: 1024 })}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "meta-keyword" })}
                                    name="metaKeyword"
                                    control={control}
                                    error={errors?.metaKeyword}
                                />
                            </Grid> */}
                </Grid>
            </SectionLayout>
            {blogCategory &&
                <BlogCategory
                    refreshData={lookUpCall}
                    open={blogCategory}
                    closeModal={() => setBlogCategoryModal(false)}
                    formMode={'create'}
                    selectedData={null}
                    headerText="Create Category"
                />
            }
            {blogTag &&
                <BlogTag
                    refreshData={lookUpCall}
                    open={blogTag}
                    closeModal={() => setBlogTagModal(false)}
                    formMode={'create'}
                    selectedData={null}
                    headerText="Create Tags"
                />
            }
        </FormLayout>
    )
}

export default Form;