import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import LinkOff from '@mui/icons-material/LinkOff';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { Add, Flag } from '@mui/icons-material';
import ApprovalIcon from '@mui/icons-material/Approval';

interface Props extends IconButtonProps {
    tooltipLabel?: string;
    imageStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

const iconStyle = {
    padding: 3,
    borderRadius: 25,
    borderStyle: 'solid',
    borderColor: 'inherit',
}

const TableViewButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FEF9DB',
        color: '#D99800'
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <Visibility htmlColor="inherit" />
            </IconButton>
        </Tooltip >
    )
});


const CreateIconButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FEF9DB',
        color: '#D99800'
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <Add htmlColor="inherit" />
            </IconButton>
        </Tooltip >
    )
});

const TableEditButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#bbdefb',
        color: '#0d47a1'
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? "Edit"}>
            <IconButton
                size="small"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <EditIcon htmlColor="inherit" />
                {/* <img src={images.editIcon} alt="edit icon" style={{ width: "25px", height: "25px" }} /> */}
            </IconButton>
        </Tooltip>
    )
});

const CustomTableButton = React.memo((props: Props & { children: JSX.Element }) => {
    const { tooltipLabel, children, style = {
        background: '#DDD',
        color: 'red'
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                {children}
            </IconButton>
        </Tooltip>
    )
});

const TableStatusButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {}, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                {...rest}
            >
                <ApprovalIcon htmlColor="inherit" />
                {/* <img src={images.refundIcon} alt="refund icon" style={{ width: "25px", height: "25px" }} /> */}
            </IconButton>
        </Tooltip>
    )
});

const TableDeleteButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FCDADA',
        color: "#FF0606"
    }, imageStyle, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="secondary"
                {...rest}
                style={{ ...iconStyle, ...style }}
            >
                <DeleteIcon htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});

const TableFlagButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#F8F8F8',
        color: "#D266D2"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <Flag color="action" htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});


const TableDeLinkButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FCDADA',
        color: "#C20000"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <LinkOff />
            </IconButton>
        </Tooltip>
    )
});

export {
    TableViewButton,
    TableDeLinkButton,
    TableEditButton,
    TableDeleteButton,
    TableFlagButton,
    TableStatusButton,
    CreateIconButton,
    CustomTableButton,
};