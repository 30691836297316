import AccessDenied from 'pages/AccessDenied';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { findCommonItems } from 'utils/global';
import { RootReducerState } from 'utils/types';

const PrivateRoute = ({ children, roles }: { children: JSX.Element ,roles: Array<string>;}) => {
  let location = useLocation();

  const { data } = useSelector(({ auth }: RootReducerState) => ({ data: auth.data,loading: auth.loading  }));
  const userHasRequiredRole = findCommonItems(roles,data?.user?.roles);

  if (!data?.accessToken) {
    return <Navigate to="/" state={{ from: location }} />;
  }


  if (data?.accessToken && !userHasRequiredRole) {
    return <AccessDenied />; // build your won access denied page (sth like 404)
  }


  return children;
};

export default PrivateRoute;