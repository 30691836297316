import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Grid } from '@mui/material';

import { CustomDatePicker, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox, CustomTextEditor } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useCreateLookupOptions, useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { FileAcceptData, fileValidate, genderList, identityDropDownList, images, masterLookupServices, statusDropDownList } from 'utils/constants';
import { services } from 'redux/services';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData } from 'redux/actions';
import { CreateIconButton } from 'components/button';
import SkillForm from 'pages/master/skill/Form'
import LanguageForm from 'pages/master/language/Form'

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState<any>(null);
    const [fileImage, setFileImage] = useState("");
    const [skillFormModal, setSkillFormModal] = useState(false);
    const [languageFormModal, setLanguageFormModal] = useState(false);
    const empUuid = localStorage.getItem('employee-uuid');

    const { employeeLookUp } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                employeeLookUp: masterLookupReducer[masterLookupServices.getEmployeeLookup].data
            })
        },
        shallowEqual
    );

    const selectOptions = useCreateLookupOptions(employeeLookUp);

    function onSubmit(data: any) {
        data.photo = data?.photo?.[0];
        data.dob = dayjs(data?.dob).format('YYYY-MM-DD');
        data.gender = data?.gender?.value;
        data.identityType = data?.identityType?.value;
        data.status = data?.status?.value;
        data.skills = data?.skills?.map((item: any) => item.value);
        data.languages = data.languages.map((item: any) => item.value);
        if (formType === 'create') data.imageUuid = empUuid;
        if (formType === 'update') data.uuid = uuid;
        const apiFunc = formType === 'create' ? 'createEmployee' : 'updateEmployee';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'employee' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            }).finally(() => setLoading(false))
    }

    function lookUpCall() {
        dispatch(getMasterLookupData(masterLookupServices.getEmployeeLookup));
    }

    useEffect(() => {
        lookUpCall()
    }, [])

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getEmployeeByUuid(uuid)
                .then((res: any) => {
                    setLoading(false);
                    const response = res.result;
                    setDefaultData(response);
                    setFileImage(response.photo)
                })
                .catch((err: any) => {
                    setLoading(false);
                    toastMessage(err.message, 'error');
                })
        }

    }, [formType === 'update', uuid]);

    useEffect(() => {
        if (defaultData) {
            delete defaultData.photo;
            reset({
                ...defaultData,
                languages: selectOptions?.language?.filter((item: any) => defaultData?.languages?.map((l: any) => +l.id)?.includes(+item.value)),
                skills: selectOptions?.skill?.filter((item: any) => defaultData?.skills?.map((s: any) => +s.id)?.includes(+item.value)),
                gender: genderList.find((item: any) => item.value === defaultData.gender),
                identityType: identityDropDownList.find((item: any) => item.value === defaultData.identityType),
                status: statusDropDownList.find((item: any) => item.value === defaultData.status),
            });
        }
    }, [defaultData])

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "employee" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "general-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "first-name" })}
                            name="firstName"
                            control={control}
                            error={errors?.firstName}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace", maxLength: 255 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "last-name" })}
                            name="lastName"
                            control={control}
                            error={errors?.lastName}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace", maxLength: 255 })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="email"
                            control={control}
                            error={errors?.email}
                            rules={validationRule.textbox({ type: "email" })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone-number" })}
                            name="phone"
                            control={control}
                            error={errors?.phone}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "dob" })}
                            name="dob"
                            error={errors.dob}
                            rules={{
                                required: true,
                                validate: (value: any) => (dayjs().diff(value, 'year') >= 18) || formatMessage({ id: "age-validation-message-18" })
                            }}
                            maxDate={new Date()}
                        />
                    </Grid>
                    <Grid item lg={2} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={genderList}
                            label={formatMessage({ id: "gender" })}
                            name="gender"
                            control={control}
                            error={errors.gender}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={2} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            type='number'
                            label={formatMessage({ id: "Houry Rate" })}
                            name="hourlyRate"
                            control={control}
                            error={errors?.hourlyRate}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={8} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "designation" })}
                            name="designation"
                            control={control}
                            error={errors?.designation}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={2} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "total-year-of-experience" })}
                            name="totalExperience"
                            control={control}
                            error={errors?.totalExperience}
                            rules={validationRule.textbox({ required: true, type: "numberWithDecimal", maxLength: 4 })}
                        />
                    </Grid>
                    <Grid item lg={2} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={statusDropDownList}
                            label={formatMessage({ id: "status" })}
                            name="status"
                            control={control}
                            error={errors.status}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel="Profile"
                            name="profile"
                            maxRows={6}
                            control={control}
                            error={errors?.profile}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

            <SectionLayout title={formatMessage({ id: "identity-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={identityDropDownList}
                            label={formatMessage({ id: "identity-type" })}
                            name="identityType"
                            control={control}
                            error={errors.identityType}
                            rules={{ required: true }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "identity-number" })}
                            name="identityNumber"
                            control={control}
                            error={errors?.identityNumber}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

            <SectionLayout title={formatMessage({ id: "address" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "city" })}
                            name="city"
                            control={control}
                            error={errors?.city}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address" })}
                            name="address"
                            control={control}
                            error={errors?.address}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "zip" })}
                            name="zip"
                            control={control}
                            error={errors?.zip}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "country" })}
                            name="country"
                            control={control}
                            error={errors?.country}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

            <SectionLayout title={formatMessage({ id: "work-information" })}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTextEditor
                            formLabel="Experience Detail"
                            name="experienceDetail"
                            defaultValue={watch('experienceDetail')}
                            error={errors?.experienceDetail}
                            onChange={(editor: any) => setValue('experienceDetail', editor)}
                            uuid={empUuid as string ?? defaultData?.imageUuid}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextEditor
                            formLabel="Academic Detail"
                            name="academicDetail"
                            defaultValue={watch('academicDetail')}
                            error={errors?.academicDetail}
                            onChange={(editor: any) => setValue('academicDetail', editor)}
                            uuid={empUuid as string ?? defaultData?.imageUuid}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextEditor
                            formLabel="Certificate Detail"
                            name="certificateDetail"
                            defaultValue={watch('certificateDetail')}
                            error={errors?.certificateDetail}
                            onChange={(editor: any) => setValue('certificateDetail', editor)}
                            uuid={empUuid as string ?? defaultData?.imageUuid}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextEditor
                            formLabel="Project Accomplished"
                            name="projectAccomplished"
                            defaultValue={watch('projectAccomplished')}
                            error={errors?.projectAccomplished}
                            onChange={(editor: any) => setValue('projectAccomplished', editor)}
                            uuid={empUuid as string ?? defaultData?.imageUuid}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xs={10}>
                                <CustomSelect
                                    defaultValue={[]}
                                    options={selectOptions?.skill ?? []}
                                    label={formatMessage({ id: "skill" })}
                                    name="skills"
                                    control={control}
                                    error={errors.skills}
                                    rules={{ required: true }}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <CreateIconButton
                                    tooltipLabel="Add"
                                    size="large"
                                    sx={{ width: '100%', height: '100%' }}
                                    color="primary"
                                    onClick={() => setSkillFormModal(true)}
                                />
                            </Grid>
                            {skillFormModal &&
                                <SkillForm
                                    refreshData={lookUpCall}
                                    open={skillFormModal}
                                    closeModal={() => setSkillFormModal(false)}
                                    formMode={'create'}
                                    selectedData={null}
                                    headerText="Create Skill"
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xs={10}>
                                <CustomSelect
                                    defaultValue={[]}
                                    options={selectOptions?.language ?? []}
                                    label={formatMessage({ id: "language" })}
                                    name="languages"
                                    control={control}
                                    error={errors.languages}
                                    rules={{ required: true }}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <CreateIconButton
                                    tooltipLabel="Add"
                                    size="large"
                                    sx={{ width: '100%', height: '100%' }}
                                    color="primary"
                                    onClick={() => setLanguageFormModal(true)}
                                />
                            </Grid>
                            {languageFormModal &&
                                <LanguageForm
                                    refreshData={lookUpCall}
                                    open={languageFormModal}
                                    closeModal={() => setLanguageFormModal(false)}
                                    formMode={'create'}
                                    selectedData={null}
                                    headerText="Create Language"
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </SectionLayout>

            <SectionLayout title={formatMessage({ id: "document" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            label={formatMessage({ id: "image" })}
                            error={errors?.photo}
                            {...register('photo', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            defaultFileUrl={fileImage}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`photo`, null);
                                clearErrors(`photo`);
                            }}
                            onChange={(file: any) => setValue('photo', file)}
                        />

                    </Grid>
                </Grid>
            </SectionLayout>

        </FormLayout>
    )
}

export default Form;