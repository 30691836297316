import Dashboard from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { ContactPageSharp, Forum, Image, PagesOutlined, PersonAdd, Settings, SixKPlusOutlined, Subscriptions, Support, VerifiedUserOutlined } from '@mui/icons-material';

import { roleData } from '../utils/constants/role';

const SidebarMenu = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: <Dashboard />,
    role: [roleData.superadmin, roleData.admin,roleData.blog,roleData.writer]
  },
  {
    title: 'Banner',
    path: '/admin/banners',
    icon: <Image />,
    role: [roleData.admin]
  },
  {
    title: 'Clients',
    path: '/admin/clients',
    icon: <VerifiedUserOutlined />,
    role: [roleData.admin]
  },
  {
    title: 'Employee',
    path: '/admin/employees',
    icon: <EngineeringIcon />,
    role: [roleData.admin]
  },
  {
    title: 'Blog',
    path: '/admin/blogs/all',
    icon: <Forum />,
    role: [roleData.admin,roleData.blog,roleData.writer]
  },
  // {
  //   title: 'Profile',
  //   path: '/admin/profile',
  //   icon: <Settings />,
  //   role: [roleData.admin],
  // },
  {
    title: 'User',
    path: '/admin/users',
    icon: <PersonAdd />,
    role: [roleData.admin]
  },
  {
    title: 'Enquiry',
    path: '/admin/enquiries',
    icon: <Support />,
    role: [roleData.admin]
  },
  {
    title: 'Pages',
    path: '/admin/pages',
    icon: <PagesOutlined />,
    role: [roleData.admin]
  },
  {
    title: 'Contact Us',
    path: '/admin/contact-us',
    icon: <ContactPageSharp />,
    role: [roleData.admin]
  },
  {
    title: 'Subscriber',
    path: '/admin/subscriber',
    icon: <Subscriptions />,
    role: [roleData.admin]
  },
  {
    title: 'Setting',
    path: '/admin/settings',
    icon: <Settings />,
    role: [roleData.admin],
    children: [
      {
        title: 'Skill',
        path: '/admin/settings/skills',
        icon: <SixKPlusOutlined />,
        role: [roleData.admin]
      },
      {
        title: 'Role',
        path: '/admin/settings/roles',
        icon: <GroupIcon />,
        role: [roleData.admin]
      },
      {
        title: 'Language',
        path: '/admin/settings/spoken-language',
        icon: <GroupIcon />,
        role: [roleData.admin]
      },
      {
        title: 'Blog Categories',
        path: '/admin/settings/blog-categories',
        icon: <GroupIcon />,
        role: [roleData.admin]
      },
      {
        title: 'Blog Tags',
        path: '/admin/settings/blog-tags',
        icon: <GroupIcon />,
        role: [roleData.admin]
      }
    ]
  }
];

export default SidebarMenu;
