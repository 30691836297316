import { avatarOptions, ChipColumnOptions, dateOptions, dateTimeOptions, FlagColumnOptions, genderOptions, labelOption } from ".";
import { blogStatusList, enquiryStatusList, statusDropDownList, statusOptions } from "..";

export const masterColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },

  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const roleColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  // {
  //   name: "guard_name",
  //   label: formatMessage({ id: "guard-name" }),
  // },
];

export const accountColumns = (formatMessage: any) => [
  {
    name: "logo_path",
    label: formatMessage({ id: "logo" }),
    ...avatarOptions
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "address",
    label: formatMessage({ id: "address" }),
    primaryColumnName: 'address_line1'
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },

  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
];

export const blogColumns = (formatMessage: any) => [
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(blogStatusList, { variant: "filled" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "featureImage",
    label: formatMessage({ id: "image" }),
    ...avatarOptions
  },
  // {
  //   name: "slug",
  //   label: formatMessage({ id: "slug" }),
  // },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  // {
  //   name: "isActive",
  //   label: formatMessage({ id: "is-active" }),
  //   ...ChipColumnOptions(statusOptions),
  //   disableSort: true,
  //   disableFilter: true,
  // },
];

export const bannerColumns = (formatMessage: any) => [
  {
    name: "files",
    label: formatMessage({ id: "image" }),
    options: {
      customBodyRender: (value: any) => {
        return <img src={value?.[0]?.url} style={{ height: 50 }} />;
      },
    },
    hideGlobalSearchFilter: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "subTitle",
    label: formatMessage({ id: "sub-title" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const employeeColumns = (formatMessage: any) => [
  {
    name: "photo",
    label: formatMessage({ id: "photo" }),
    ...avatarOptions
  },
  {
    name: "firstName",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    ...genderOptions,
    label: formatMessage({ id: "gender" }),
    name: "gender",
  },
  {
    name: "dob",
    label: formatMessage({ id: "dob" }),
    ...dateOptions,
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusDropDownList),
    disableSort: true,
    disableFilter: true,
  },
];


export const agentColumns = (formatMessage: any) => [
  {
    name: "profile_photo_url",
    label: formatMessage({ id: "photo" }),
    ...avatarOptions
  },
  {
    name: "full_name_search",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    ...genderOptions,
    label: formatMessage({ id: "gender" }),
    name: "gender",
  },
  {
    name: "dob_ad",
    label: formatMessage({ id: "dob" }),
    primaryColumnName: "dob_ad",
    ...dateOptions,
  },
  {
    name: "is_flagged",
    label: formatMessage({ id: "is-flagged" }),
    ...FlagColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const userColumns = (formatMessage: any) => [
  {
    name: 'userName', label: formatMessage({ id: "username" }),
  },
  {
    name: 'fullName', label: formatMessage({ id: "name" }),
  },
  {
    name: 'email', label: formatMessage({ id: "email" }),
    hideGlobalSearchFilter: true,
  },
  {
    name: 'roles', label: formatMessage({ id: "role" }),
    options: {
      customBodyRender: (value: any) => {
        return <span>{value?.map((item: any) => item.name)}</span>;
      },
    },
    hideGlobalSearchFilter: true,
  },
]

export const enquiryColumns = (formatMessage: any) => [
  {
    name: 'user',
    label: formatMessage({ id: "User" }),
    options: {
      customBodyRender: (value: any) => {
        return <span>{value?.fullName}</span>;
      },
    },
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    name: "desiredSkill",
    label: formatMessage({ id: "Desired Skill" }),
  },
  {
    name: 'employee',
    label: formatMessage({ id: "Employee Name" }),
    options: {
      customBodyRender: (value: any) => {
        return <span>{value?.fullName}</span>;
      },
    },
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(enquiryStatusList),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "requestedAt",
    label: formatMessage({ id: "requested-at" }),
    primaryColumnName: "requestedAt",
    ...dateTimeOptions,
  },
];


export const clientColumns = (formatMessage: any) => [
  {
    name: "image",
    label: formatMessage({ id: "image" }),
    options: {
      customBodyRender: (value: any) => {
        return <img src={value} style={{ height: 50 }} />;
      },
    },
    hideGlobalSearchFilter: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "designation",
    label: formatMessage({ id: "designation" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const pageColumns = (formatMessage: any) => [
  {
    name: "slug",
    label: formatMessage({ id: "slug" }),
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const contactUsColumns = (formatMessage: any) => [
  {
    name: "fullName",
    label: formatMessage({ id: "full-name" }),
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "subject",
    label: formatMessage({ id: "subject" }),
  },
  {
    name: "createdAt",
    label: formatMessage({ id: "created-at" }),
    ...dateOptions,
    disableSort: true,
    disableFilter: true,
  },
];


export const subscriberColumns = (formatMessage: any) => [
 
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "createdAt",
    label: formatMessage({ id: "created-at" }),
    ...dateOptions,
    disableSort: true,
    disableFilter: true,
  },
];

