import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import CustomTable from "components/table";
import {
    TableEditButton,
    DeleteButton,
    TableButtonGroup,
    TableStatusButton,
    TableViewButton,
    ConfirmationButton,
} from "components/button";
import { masterPaginationServices, tableInitialState } from "utils/constants";
import { getTableParams } from "utils/global";
import { RootReducerState } from "utils/types";
import { getMasterPaginationData } from "redux/actions";
import PageLayout from "layouts/PageLayout";
import { blogColumns } from "utils/constants/tableColumn/column";
import { services } from "redux/services";
import { useCheckRole, useToastMessage } from "hooks";
import UpdateStatus from "./UpdateStatus";
import View from "./View";
import CustomCandidateTab from "./CustomCandidateTab";
import { Drawer, Typography } from "@mui/material";
import { Check, Remove } from "@mui/icons-material";

const Blog: React.FC = () => {
    const { status } = useParams();
    const { isAdmin, isBlog } = useCheckRole();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [defaultData, setDefaultData] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { tableData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            tableData: masterPaginationReducer[masterPaginationServices.blog].data,
            loading: masterPaginationReducer[masterPaginationServices.blog].loading,
        }),
        shallowEqual
    );

    const {
        items,
        meta: { totalItems },
    } = tableData;

    useEffect(() => {
        onApiCall();
    }, [tableState, status]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        params.status = status;
        dispatch(getMasterPaginationData(masterPaginationServices.blog, params));
    }

    let columnAction = [{
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = items[tableMeta.rowIndex];
                return (
                    <TableButtonGroup>
                        <TableViewButton
                            tooltipLabel={"View " + formatMessage({ id: "blog" })}
                            onClick={() => handleViewClick(rowData)}
                        />
                        {(isAdmin || isBlog) && (
                            <TableEditButton
                                tooltipLabel={"Edit " + formatMessage({ id: "blog" })}
                                onClick={() => handleRowClick(rowData)}
                            />
                        )}
                        {(isAdmin || isBlog) && rowData?.status === "pending" && (
                            <TableStatusButton
                                tooltipLabel={
                                    "Update " + formatMessage({ id: "blog" }) + " Status"
                                }
                                onClick={() => {
                                    handleClickOpen();
                                    setDefaultData(rowData);
                                }}
                            />
                        )}
                        {isAdmin && rowData?.status === "pending" && (
                            <DeleteButton onDelete={() => onDeleteData(rowData)} />
                        )}
                    </TableButtonGroup>
                );
            },
        },
    }];

    if (isAdmin || isBlog) {
        columnAction.push({
            label: formatMessage({ id: "show-in-banner" }),
            name: "",
            options: {
                customBodyRender: (_: any, tableMeta: any) => {
                    const rowData = items[tableMeta.rowIndex];
                    return rowData?.status === "published" ? (
                        <>
                            <ConfirmationButton
                                label={rowData.isFeatured == 0 ? "Add" : 'Remove'}
                                variant="outlined"
                                color={rowData.isFeatured == 0 ? "success" : 'error'}
                                startIcon={rowData.isFeatured == 0 ? <Check /> : <Remove />}
                                onConfirm={() => updateFeaturedData(rowData.id, rowData.isFeatured)}
                                title={rowData.isFeatured == 1 ? "Remove from banner" : 'Add to banner'}
                                subTitle="Press Agree button if you want to update else press Disagree."
                            />
                        </>
                    ) : <Typography>-</Typography>;
                },
            },
        });
    }

    function handleRowClick(rowData: any) {
        window.open(`/admin/blogs/update/${rowData?.uuid}`)
        // navigate(`/admin/blogs/update/${rowData?.uuid}`, { ...rowData });
    }

    function handleViewClick(rowData: any) {
        setModalView(true);
        setDefaultData(rowData);
    }

    function updateFeaturedData(id: number, isFeatured: boolean) {
        setDeleteLoading(true);
        services
            .updateFeaturedBlog({ id, isFeatured: !isFeatured })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "updated-message" }, { title: formatMessage({ id: "featured" }) }));
            })
            .catch((err) => {
                toastMessage(
                    err?.data?.message
                        ? err?.data?.message
                        : formatMessage({ id: "opps-error-message" }),
                    "error"
                );
            })
            .finally(() => setDeleteLoading(false));
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services
            .deleteBlog(data.uuid)
            .then((res: any) => {
                onApiCall();
                toastMessage(
                    formatMessage(
                        { id: "deleted-message" },
                        { title: formatMessage({ id: "blog" }) }
                    )
                );
            })
            .catch((err) => {
                toastMessage(
                    err?.data?.message
                        ? err?.data?.message
                        : formatMessage({ id: "opps-error-message" }),
                    "error"
                );
            })
            .finally(() => setDeleteLoading(false));
    }

    return (
        <PageLayout metaTitle={formatMessage({ id: "blog" })} hideToolbar>
            <CustomCandidateTab
                status={status}
                totalItems={totalItems}
                onChange={(tab: string) => {
                    navigate(`/admin/blogs/${tab}`);
                    setTableState(tableInitialState);
                }}
            />

            <CustomTable
                columns={[...blogColumns(formatMessage), ...columnAction.reverse()]}
                tableData={items}
                tableState={tableState}
                rowsCount={totalItems}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
            <UpdateStatus
                open={open}
                handleClose={handleClose}
                defaultData={defaultData}
                refreshData={onApiCall}
            />
            {modalView && (
                <Drawer
                    variant="permanent"
                    open={modalView}
                    onClose={() => setModalView(false)}
                    anchor="bottom"
                    sx={{ width: '100%' }}
                >
                    <View defaultData={defaultData} onClose={() => setModalView(false)} />
                </Drawer>
            )}
        </PageLayout>
    );
};

export default Blog;
