import { useMemo } from 'react';
import { SelectOptionsState } from 'utils/types';

interface Params {
    [key: string]: { [key: string]: any }[]
}

export function useCreateUuidLookupOptions(data: Params) {

    const lookupData = useMemo(() => {
        return Object.keys(data).reduce((acc: any, curr) => {
            return (acc[curr] = data[curr]?.filter((option: any) => option.title)?.map((option: any) => ({ label: option.title, value: option.uuid })), acc)
        }, {})
    }, [data])

    return lookupData;
}

export function useCreateLookupOptions(data: Params) {

    const lookupData = useMemo(() => {
        return Object.keys(data).reduce((acc: any, curr) => {
            return (acc[curr] = data[curr]?.filter((option: any) => option.title)?.map((option: any) => ({ label: option.title, value: option.id })), acc)
        }, {})
    }, [data])

    return lookupData;
}


export function useCreateLeadSourceLookupOptions(data: { [key: string]: any }[]) {
    const lookupData = useMemo(() => {
        return data.reduce((acc: any, curr: any) => {
            let option = { label: curr.name, value: String(curr.id) };
            acc[`leadSource${curr.leadSourceOrder}`] = acc?.[`leadSource${curr.leadSourceOrder}`] ? [option, ...acc[`leadSource${curr.leadSourceOrder}`]] : [option];
            return acc;
        }, {});

    }, [data])

    return lookupData;
}

export function useCreateDropdownOptions(data: { [key: string]: any }[], filterKey: string | null = null, filterValue: string | boolean | number | null | undefined = undefined, labelValueKey: [string, string] = ["name", "id"]): SelectOptionsState[] {
    const lookupData = useMemo(() => {
        let result = data;
        if (filterKey && filterValue !== undefined) {
            // 1st Filtering value with item key value
            // 2nd Filtering dublicate Values with item key
            result = result.filter(item => String(item[filterKey]) === String(filterValue))
                .reduce((acc: { [key: string]: any }[], curr: { [key: string]: any }) => {
                    let key = labelValueKey[1];
                    let isItemExist = acc.find(item => item[key] === curr[key]);
                    return isItemExist ? acc : [...acc, curr];
                }, []);
        }
        return result.map(item => ({ label: item[labelValueKey[0]], value: item[labelValueKey[1]] }));
    }, [data, filterValue]);

    return lookupData;

}

export function createDropdownOptions(data: { [key: string]: any }[]) {
    const lookupData = data.map(d => ({ label: d.name, value: d.id }))

    return lookupData;
}


export function useGetUniqueDropDownOptions(data: { [key: string]: any }[], filterKey: string, labelValueKey: [string, string]) {
    let response = useMemo(() => {
        let result = data.reduce((acc: { [key: string]: any }[], curr: { [key: string]: any }) => {
            let isItemExist = acc.find(item => item[filterKey] === curr[filterKey]);
            return isItemExist ? acc : [...acc, curr];
        }, []);

        return result.map(item => ({ label: item[labelValueKey[0]], value: item[labelValueKey[1]] }));
    }, [data]);

    return response;
}