import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Grid, InputAdornment, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { CustomCheckBox, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';

interface Props {
    open: boolean;
    closeModal: () => void;
    userId: number;
    headerText: string;
    refreshData: () => void;
}

const UpdatePassword = (props: Props) => {
    const { closeModal, open, userId, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, reset, watch } = useForm<any>({ mode: 'all', defaultValues: { is_active: true } });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.id = userId;
        services['updateUserPassword'](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: 'Updated' }, { title: "blog-category" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err.message, 'error');
            })
    }

    return (
        <CustomDrawer
            formMode="update"
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "password" })}
                        name="password"
                        control={control}
                        error={errors?.password}
                        type="password"
                        rules={{
                            ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                            validate: (value: any) => (watch('confirmation_password') ? (watch('confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                        }}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                    <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                        <InfoIcon color="primary" />
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "confirm-password" })}
                        name="confirmation_password"
                        control={control}
                        error={errors?.confirmation_password}
                        type="password"
                        rules={{
                            ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                            validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                        }}
                        autoComplete="off"
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default UpdatePassword;