import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDayjs';
import createCache from "@emotion/cache"
import LanguageProvider from './i18n';

import RootProvider from 'utils/context/RootProvider';
import MainRoute from 'routes';
import ThemeProvider from 'theme/theme';
import { persistor, store } from 'redux/store';

import networkInterceptor from 'utils/global/networkInterceptor';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getUuid } from 'utils/global';

networkInterceptor(store);

export const muiCache = createCache({
  key: 'mui', // all material ui classes start with 'css' instead of 'mui' even with this here
  prepend: true,
});


const setLocalStorageUUID = (key: string, value: string) => {
  if (!localStorage.getItem(key)) {
    localStorage.setItem(key, value);
  }
};

const removeLocalStorageUUID = (key: string) => {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
};

const pathsAndKeys = [
  { path: '/admin/blogs/create', key: 'blog-uuid' },
  { path: '/admin/banners/create', key: 'banner-uuid' },
  { path: '/admin/employees/create', key: 'employee-uuid' },
  { path: '/admin/pages/create', key: 'page-uuid' },
];

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    pathsAndKeys.map(({ path, key }) => {
      if (pathname === path) {
        setLocalStorageUUID(key, getUuid());
      } else {
        removeLocalStorageUUID(key);
      }
    });
  }, [pathname]);

  return (
    <CacheProvider value={muiCache}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <LanguageProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <RootProvider>
                  <MainRoute />
                </RootProvider>
              </LocalizationProvider>
            </LanguageProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CacheProvider>
  );
}

export default App;