import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {  Drawer,} from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';

// components
import Logo from './Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import sidebarMenu from 'routes/menu';

const DRAWER_WIDTH = 230;

const RootStyle = styled('div')(({ theme }:any) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));


const LogoStyle = styled('div')(({ theme }:any) => ({
  // padding: '0 24px',
   display: 'inline-flex',
  // padding: theme.spacing(2, 2.5),
    borderBottom:`1px solid ${theme.palette.action.hover}`
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }:any) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <LogoStyle>
        <Logo />
      </LogoStyle>
      <NavSection navConfig={sidebarMenu} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
