import axios, { AxiosPromise } from "axios";
import { API_ENDPOINTS } from "redux/apiEndPoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any
}

export const appServices = {
  logoutUser: (params: ParamsState): AxiosPromise => axios.get(API_ENDPOINTS.LOGOUT, { params }),
  dashboard: (): AxiosPromise => axios.get(API_ENDPOINTS.DASHBOARD),

  createEmployee: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_EMPLOYEE, body),
  updateEmployee: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_EMPLOYEE, body),
  getAllEmployee: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_EMPLOYEE, body),
  getEmployeeByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_EMPLOYEE_BY_UUID + '/' + uuid),
  deleteEmployee: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_EMPLOYEE + '/' + uuid),
  getEmployeeLookup: () => new AxiosService().get(API_ENDPOINTS.EMPLOYEE_LOOKUP),
  updateEmployeeFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_EMPLOYEE_FLAG, body),

  createBlog: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_BLOG, body),
  updateBlog: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_BLOG, body),
  getAllBlog: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_BLOG, body),
  getBlogByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_BLOG_BY_UUID + '/' + uuid),
  deleteBlog: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_BLOG + '/' + uuid),
  updateStatusBlog: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_BLOG_STATUS, body),
  updateFeaturedBlog: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_BLOG_FEATURED, body),
  getBlogLookup: () => new AxiosService().get(API_ENDPOINTS.BLOG_LOOKUP),

  createBanner: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_BANNER, body),
  updateBanner: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_BANNER, body),
  getAllBanner: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_BANNER, body),
  getBannerByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_BANNER_BY_UUID + '/' + uuid),
  deleteBanner: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_BANNER + '/' + uuid),

  createUser: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_USER, body),
  updateUser: (body: ParamsState) => new AxiosService().patch(API_ENDPOINTS.UPDATE_USER, body),
  getAllUser: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_USER, body),
  getUserByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_USER_BY_UUID + '/' + uuid),
  deleteUser: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_USER + '/' + uuid),
  getUserLookup: () => new AxiosService().get(API_ENDPOINTS.USER_LOOKUP),
  updateUserFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_USER_FLAG, body),
  updateUserPassword: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.USER_UPDATE_PASSWORD, body),

  getAllEnquiry: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ENQUIRY, body),
  getEnquiryByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_ENQUIRY_BY_UUID + '/' + uuid),
  deleteEnquiry: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_ENQUIRY + '/' + uuid),
  updateStatusEnquiry: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_ENQUIRY_STATUS, body),
  
  createClient: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_CLIENT, body),
  updateClient: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_CLIENT, body),
  getAllClient: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_CLIENT, body),
  getClientByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_CLIENT_BY_UUID + '/' + uuid),
  deleteClient: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_CLIENT + '/' + uuid),

  createPage: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_PAGE, body),
  updatePage: (body: ParamsState) => new AxiosService().patch(API_ENDPOINTS.UPDATE_PAGE, body),
  getAllPage: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_PAGE, body),
  getPageByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_PAGE_BY_UUID + '/' + uuid),
  deletePage: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_PAGE + '/' + uuid),

  getAllSubscriber: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_SUBSCRIBER, body),
  deleteSubscriber: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_SUBSCRIBER + '/' + uuid),

  getAllContactUs: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_CONTACT_US, body),
  deleteContactUs: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_CONTACT_US + '/' + uuid),

  getUserLogout: (): AxiosPromise => axios.post(API_ENDPOINTS.LOGOUT),

}