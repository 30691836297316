import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Select } from 'components/forms';
import { blogStatusList } from 'utils/constants';
import { useIntl } from 'react-intl';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { useEffect, useState } from 'react';
import { ConfirmationButton } from 'components/button';

export default function UpdateStatus({ open, handleClose, defaultData, refreshData }: any) {
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const [status, setStatus] = useState<any>(null);

    useEffect(() => {
        if (defaultData?.status) {
            setStatus(blogStatusList.find(item => item.value == defaultData?.status))
        }

    }, [defaultData])

    function onSubmit() {
        if (status.value) {
            services.updateStatusBlog({
                id: defaultData.id,
                status: status.value
            })
                .then((res: any) => {
                    toastMessage(formatMessage({ id: "updated-message" }, { title: formatMessage({ id: "status" }) }));
                    handleClose();
                    refreshData()
                })
                .catch((err: any) => {
                    toastMessage(err.data.message, 'error');
                })
        } else {
            toastMessage('Please select status', 'error');
        }
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Update Blog Status</DialogTitle>
            <DialogContent sx={{ padding: "15px 10px" }}>
                <Select
                    options={blogStatusList?.filter(x => x.value !== 'all')}
                    // label={formatMessage({ id: "status" })}
                    disableClearable
                    value={status}
                    fullWidth
                    onChange={(_, data) => {
                        setStatus(data)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} fullWidth color="error" variant='contained'>Cancel</Button>
                <ConfirmationButton
                    label='Update'
                    onConfirm={onSubmit}
                    fullWidth
                    variant='contained'
                    title='Change Status'
                    subTitle='Are you sure you want to update status of this record? Press Agree button if you want to update else press Disagree.'
                />
            </DialogActions>
        </Dialog>
    );
}