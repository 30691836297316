import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// material
import { Box, Button, Card, Grid, Stack } from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate, images, masterLookupServices } from 'utils/constants';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData } from 'redux/actions';
import { Container } from '@mui/system';
import { Save } from '@mui/icons-material';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();

    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [updateId, setUpdateId] = useState<number>(0);
    const [provinceOption, setProvinceOption] = useState();
    const [districtOption, setDistrictOption] = useState();
    const [municipalitiesOption, setMunicipaltitesOption] = useState();

    const [fileData, setFileData] = useState("");

    const { employeeLookup, authData } = useSelector(
        ({ masterLookupReducer, auth }: RootReducerState) => {
            return ({
                employeeLookup: masterLookupReducer[masterLookupServices.getEmployeeLookup].data,
                authData: auth.data?.user
            })
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(getMasterLookupData(masterLookupServices.getEmployeeLookup));
    }, [])

    useEffect(() => {
        if (employeeLookup) {
            const provinceSelectState = employeeLookup?.province?.map((item: any) => ({
                value: item?.id,
                label: item?.name,
                districts: item?.districts?.map((dis: any) => ({
                    value: dis?.id,
                    label: dis?.name,
                    municipalities: dis?.municipalities?.map((mun: any) => ({
                        value: mun?.id,
                        label: mun?.name,
                    }))
                }))
            }));

            setProvinceOption(provinceSelectState)
        }
    }, [employeeLookup])

    function onSubmit(data: any) {
        setLoading(true);
        data.logo = data?.logo?.[0];
        data.id = updateId;
        data.uuid = authData.account_uuid;
        data.address.province_id = data?.address?.province_id?.value;
        data.address.district_id = data?.address?.district_id?.value;
        data.address.municipality_id = data?.address?.municipality_id?.value;
        const apiFunc = 'updateUser';
        const message = 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'user' }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (authData?.account_uuid) {
            // setLoading(true);
            services.getUserByUuid(authData.account_uuid)
                .then((res: any) => {
                    const response = res.result;
                    setUpdateId(+response.id);
                    reset({
                        general_information: {
                            name: response.name,
                            short_description: response.short_description,
                            phone: response.phone,
                            contact: response.contact,
                            email: response.email,
                            is_active: response.is_active,
                            description: response.description,
                        },
                        address: {
                            address_line1: response.address_line1,
                            address_line2: response.address_line2,
                            province_id: { label: response?.province?.name, value: response?.province?.id },
                            district_id: { label: response?.district?.name, value: response?.district?.id },
                            municipality_id: { label: response?.municipality?.name, value: response?.municipality?.id },
                            state: response.state,
                            zip: response.zip,
                        },
                    });
                    setFileData(response.logo_path)
                })
                .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
                })
                .finally(() => setLoading(false))
        }

    }, [formType === 'update', authData?.account_uuid]);

    return (
        <Container maxWidth="xl" style={{ maxWidth : '100%'}}>
                {loading && <HoverLoader />}
                <SectionLayout title={formatMessage({ id: "profile-information" })}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "blog-name" })}
                                name="general_information.name"
                                control={control}
                                error={errors?.general_information?.name}
                                rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                            />
                        </Grid>

                        <Grid item lg={8} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "slogan" })}
                                name="general_information.short_description"
                                control={control}
                                error={errors?.general_information?.slogan}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "phone-number" })}
                                name="general_information.phone"
                                control={control}
                                error={errors?.general_information?.phone}
                                rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "contact" })}
                                name="general_information.contact"
                                control={control}
                                error={errors?.general_information?.contact}
                                rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "email" })}
                                name="general_information.email"
                                control={control}
                                error={errors?.general_information?.email}
                                rules={validationRule.textbox({ type: "email", required: true })}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <CustomTextArea
                                formLabel={formatMessage({ id: "blog-detail" })}
                                name="general_information.description"
                                control={control}
                                minRows={6}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomCheckBox
                                name="general_information.is_active"
                                label={formatMessage({ id: "is-active" })}
                                control={control}
                            />
                        </Grid>
                    </Grid>
                </SectionLayout>
                <SectionLayout title={formatMessage({ id: "profile-address" })}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomSelect
                                options={provinceOption ?? []}
                                label={formatMessage({ id: "province" })}
                                name="address.province_id"
                                control={control}
                                onChangeValue={(data) => setDistrictOption(data.districts)}
                                error={errors?.address?.province_id}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomSelect
                                options={districtOption ?? []}
                                label={formatMessage({ id: "district" })}
                                name="address.district_id"
                                control={control}
                                onChangeValue={(data) => setMunicipaltitesOption(data.municipalities)}
                                error={errors?.address?.district_id}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomSelect
                                options={municipalitiesOption ?? []}
                                label={formatMessage({ id: "municipality" })}
                                name="address.municipality_id"
                                control={control}
                                error={errors?.address?.municipality_id}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "address-line1" })}
                                name="address.address_line1"
                                control={control}
                                error={errors?.address?.address_line1}
                                rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "address-line2" })}
                                name="address.address_line2"
                                control={control}
                                error={errors?.address?.address_line2}
                                rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "zip" })}
                                name="address.zip"
                                control={control}
                                error={errors?.address?.zip}
                                rules={validationRule.textbox({ type: "number" })}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "country" })}
                                name="address.country"
                                control={control}
                                error={errors?.address?.country}
                                defaultValue="Nepal"
                                rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </SectionLayout>
                {formType === 'create' &&
                    <SectionLayout title={formatMessage({ id: "credential" })}>
                        <Grid container spacing={2}>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "login-email" })}
                                    name="credential.email"
                                    control={control}
                                    error={errors?.credential?.email}
                                    rules={validationRule.textbox({ required: true, type: "email" })}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "password" })}
                                    name="credential.password"
                                    control={control}
                                    error={errors?.credential?.password}
                                    type="password"
                                    rules={{
                                        ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                        validate: (value: any) => (watch('confirmation_passwordasda') ? (watch('confirmation_passwordasda') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                    }}
                                    autoComplete="off"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                                <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                                    <InfoIcon color="primary" />
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "confirm-password" })}
                                    name="credential.confirmation_password"
                                    control={control}
                                    error={errors?.credential?.confirmation_passwordasda}
                                    type="password"
                                    rules={{
                                        ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                        validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                    }}
                                    autoComplete="off"
                                />
                            </Grid>
                        </Grid>
                    </SectionLayout>
                }

                <SectionLayout title={formatMessage({ id: "profile-files" })}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                                accept={FileAcceptData.IMAGES}
                                label={formatMessage({ id: "logo" })}
                                error={errors?.logo}
                                {...register('photo', {
                                    validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                                })}
                                defaultFileUrl={fileData}
                                defaultFileName={''}
                                onClear={() => {
                                    setValue(`logo`, null);
                                    clearErrors(`logo`);
                                }}
                                onChange={(file: any) => setValue('logo', file)}
                            />
                        </Grid>
                    </Grid>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" my={5}>
                        <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)} startIcon={<Save />}>
                            Submit
                        </Button>
                    </Stack>
                </SectionLayout>
        </Container>
    )
}

export default Form;