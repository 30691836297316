
import { useEffect, useState } from "react";

const View = ({ defaultData }: any) => {

    const [detail, setDetail] = useState<any>({})

    useEffect(() => {
        if (defaultData) {
            setDetail(defaultData)
        }
    }, [defaultData])

    return <div className="ql-snow">
        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: detail?.message }}>
        </div>
    </div>
}
export default View;
